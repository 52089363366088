angular.module('MyHippoProducer.Components').directive('dynamicTile', function() {
    return {
        restrict: 'E',
        link: function($scope) {
            $scope.mapToValue = (fieldKey) => $scope.item[fieldKey].display;

            $scope.getContentUrl = function() {
                return `/components/form/tiles/layouts/tile-content-views/${$scope.section.properties.key}-tile.html`;
            };
        },
        template: '<div ng-include="getContentUrl()" style="height: 100%"></div>'
    };
});
